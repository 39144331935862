import { OverlayModule } from '@angular/cdk/overlay';
import { APP_BASE_HREF, PlatformLocation } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { enableProdMode, importProvidersFrom, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { bootstrapApplication, BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';

import { EffectsModule } from '@ngrx/effects';
import { ActionReducer, MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { JwtModule } from '@auth0/angular-jwt';
import { environment } from '@ph-env/environment';
import { localStorageSync } from 'ngrx-store-localstorage';
import { CookieService } from 'ngx-cookie-service';

import { DocumentRef, WindowRef } from '@ph-core/reference';
import { TranslationLoader } from '@ph-core/translate-loader/translate.loader';
import { CONTRACT_HISTORY_CONFIGURATION } from '@ph-shared/components';
import { ShoppingStepperEffects } from '@ph-shared/modules/shopping-stepper';
import { CartEffects } from '@ph-store/cart/cart.effects';
import { ClaimsEffects } from '@ph-store/claims/claims.effects';
import { ConfigEffects } from '@ph-store/config/config.effects';
import { HistoryEffects } from '@ph-store/history/history.effects';
import { PrismicEffects } from '@ph-store/prismic/prismic.effects';
import { ProductEffects } from '@ph-store/product/product.effects';
import { reducers } from '@ph-store/reducers';
import { RemitEffects } from '@ph-store/remit/remit.effects';
import { UserEffects } from '@ph-store/user/user.effects';
import { VehicleEffects } from '@ph-store/vehicle/vehicle.effects';

import { APP_ROUTES } from './app/app-routes';
import { AppComponent } from './app/app.component';
import { RequestInterceptor } from './app/interceptor';

export function localStorageSyncReducer<T>(reducer: ActionReducer<T>): ActionReducer<T> {
  return localStorageSync({
    keys: ['userState', 'productState', 'cartState', 'vehicleState', 'prismicState', 'shoppingStepperState'],
    rehydrate: true,
  })(reducer);
}

const metaReducers: MetaReducer[] = [localStorageSyncReducer];

// required for AOT compilation
const HttpLoaderFactory = (http: HttpClient): TranslationLoader => new TranslationLoader(http);

const tokenGetter = (): string => inject(CookieService).get('token');

if (environment.production) {
  enableProdMode();
} else {
  if (environment?.apiMock?.enabled) {
    // eslint-disable-next-line
    const { worker } = require('./mocks/browser');
    worker.start({
      onUnhandledRequest: environment?.apiMock?.showWarnings ? 'warn' : 'bypass',
    });
  }
}

bootstrapApplication(AppComponent, {
  providers: [
    provideRouter(APP_ROUTES),
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      ReactiveFormsModule,
      JwtModule.forRoot({
        config: {
          tokenGetter,
          allowedDomains: [],
          disallowedRoutes: [],
        },
      }),
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient],
        },
      }),
      StoreModule.forRoot(reducers, {
        metaReducers,
        runtimeChecks: {
          strictActionImmutability: false,
          strictActionSerializability: false,
          strictActionTypeUniqueness: false,
          strictActionWithinNgZone: false,
          strictStateImmutability: false,
          strictStateSerializability: false,
        },
      }),
      StoreDevtoolsModule.instrument({ maxAge: 25 }),
      EffectsModule.forRoot([
        PrismicEffects,
        ProductEffects,
        ConfigEffects,
        CartEffects,
        UserEffects,
        VehicleEffects,
        RemitEffects,
        HistoryEffects,
        ClaimsEffects,
        ShoppingStepperEffects,
      ]),
      OverlayModule
    ),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true,
    },
    WindowRef,
    DocumentRef,
    {
      provide: APP_BASE_HREF,
      useFactory: (s: PlatformLocation): string => s.getBaseHrefFromDOM(),
      deps: [PlatformLocation],
    },
    {
      provide: CONTRACT_HISTORY_CONFIGURATION,
      useValue: { apiRoot: environment.apiUrl },
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
});
