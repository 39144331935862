<!--COPIED FROM EASE QUOTE PRINT PAGE-->
<div class="centering-page">
  <div class="d-flex justify-content-between">
    <div class="title text-align-center headline">
      {{ verbiage.remitPage.print.register | translate }}
    </div>
    <div class="logo">
      <img [src]="navbarLogo()" alt="Safe-guard-products" style="width: 100%;"/>
    </div>
  </div>

  <div class="w-100 mt-5">
    <!-- FIRST DEALER ROW -->
    <div class="d-flex">
      <div class="div-cell border-left t-col-6">
        <div class="text-label text-grey"> DEALER/SELLER NAME</div>
        <div class="text-content text-align-center">
          {{ user().name }}
        </div>
      </div>
      <div class="div-cell t-col-6">
        <div class="text-label text-grey"> DEALER #</div>
        <div class="text-content text-align-center">
          {{ user().cmsDealerNumber }}
        </div>
      </div>
    </div>

    <!--SECOND DEALER ROW-->
    <div class="d-flex">
      <div class="div-cell border-left t-col-6">
        <div class="text-label text-grey"> STREET ADDRESS</div>
        <div class="text-content text-align-center">
          {{ user().dealerInfo?.dealerAddress1 }}
        </div>
      </div>
      <div class="div-cell t-col-2">
        <div class="text-label text-grey"> CITY</div>
        <div class="text-content text-align-center">
          {{ user().dealerInfo?.dealerCity }}
        </div>
      </div>
      <div class="div-cell t-col-1">
        <div class="text-label text-grey"> STATE</div>
        <div class="text-content text-align-center">
          {{ user().dealerInfo?.dealerState }}
        </div>
      </div>
      <div class="div-cell t-col-1">
        <div class="text-label text-grey"> ZIP CODE</div>
        <div class="text-content text-align-center">
          {{ user().dealerInfo?.dealerZipCode }}
        </div>
      </div>
      <div class="div-cell t-col-2">
        <div class="text-label text-grey"> DEALER PHONE #</div>
        <div class="text-content text-align-center">
          {{ user().dealerInfo?.dealerPhone ? user().dealerInfo?.dealerPhone : 'N/A' }}
        </div>
      </div>
    </div>

    <!--THIRD DEALER ROW-->
    <div class="d-flex">
      <div class="div-cell border-left border-bottom t-col-2">
        <div class="text-label text-grey"> DATE SUBMITTED</div>
        <div class="text-content text-align-center">
          {{ remitted() ? (date | date) : 'PENDING' }}
        </div>
      </div>
      <div class="div-cell border-bottom t-col-4">
        <div class="text-label text-grey"> COMPLETED BY</div>
        <div class="text-content text-align-center">
          {{ user().fullName }}
        </div>
      </div>
      <div class="div-cell border-bottom t-col-3">
        <div class="text-label text-grey"> TITLE</div>
        <div class="text-content text-align-center">N/A</div>
      </div>
      <div class="div-cell border-bottom t-col-3">
        <div class="text-label text-grey"> EXTENSION</div>
        <div class="text-content text-align-center">N/A</div>
      </div>
    </div>

    <!-- FOURTH ROW -->
    <div class="d-flex">
      <div class="center-note border-left border-right w-100 text-uppercase">
        NOTE: The following contracts have been {{ verbiage.remitPage.print.registered | translate }} and no further
        action is required at this time.
      </div>
    </div>

    <!-- FIFTH ROW -->
    <div class="d-flex text-grey">
      <div class="border-top border-right fs-10 py-2 border-left text-align-center col-customer">CUSTOMER NAME</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-sale-date">SALE DATE</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-year">YEAR</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-model">MODEL</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-vin">VIN / SERIAL NUMBER</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-product">PRODUCT</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-term">TERM</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-contract">CONTRACT#</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-dealer-cost">DEALER COST</div>
      <div class="border-top border-right fs-10 py-2 text-align-center col-total-cost">TOTAL COST</div>
    </div>
    @for (item of contracts(); track item; let i = $index) {
      <div>
        @if (i !== 0 && (i === 7 || (i - 7) % 15 === 0)) {
          <div>
            <div style="page-break-after: always"></div>
            <div style="height: 50px"></div>
          </div>
        }

        <div class="d-flex">
          <div class="border-top border-right fs-10 py-2 border-left text-align-center col-customer"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.businessFlag === 'Y' ? item.businessName : item.firstName + ' ' + item.lastName }} &nbsp;
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-sale-date"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.saleDate }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-year"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.year }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-model"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.productCode === 'GMWC' ? 'POWERUP' : item.model }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-vin"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.vin }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-product"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.productCode }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-term"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.termMonths }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-contract"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            {{ item.econContractNumber }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-dealer-cost"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            ${{ item.contractDealerCost }}
          </div>
          <div class="border-top border-right fs-10 py-2 text-align-center col-total-cost"
               [class.border-bottom]="$last || (i !== 0 && (i === 6 || (i - 6) % 15 === 0))">
            ${{ item.customerCost }}
          </div>
        </div>
      </div>
    }
    <div class="bottom-line d-flex justify-content-end border-bottom border-right border-left">
      <div class="total">
          <span>
            TOTAL DEALER COST&nbsp;&nbsp;
          </span>
        <span style="font-size: 1.5em;">
            ${{ totalDealerCost() }}
          </span>
      </div>
    </div>
  </div>
</div>
