import { Env } from '@ph-core/enums';
import { Environment } from '@ph-env/environment.model';
import { environment as baseHondaEnvironment } from '@ph-env/honda/environment.honda.base';

export const environment: Environment = {
  ...baseHondaEnvironment,
  type: Env.UAT,
  production: true,
  apiUrl: 'https://uathondacaregapcom.sgdavinci.com',
  okta: {
    SSOCredentials: {
      ...baseHondaEnvironment.okta.SSOCredentials,
      issuer: 'https://safeguard-uat.oktapreview.com/oauth2/aus2oug9jmJaurlRr1d7',
      clientId: '0oa4s6y9l2IUU3r2F1d7',
      idps: [
        {
          idpId: '0oa45tqipobmQGznD1d7',
          buttonLabelTranslationKey: 'loginPage.loginForm.loginUsingOkta',
        },
      ],
    },
  },
  cancellationUrl: 'https://staging-cancellationui.safe-guardproducts.com/cancellation-portal/#/hondacareus',
};
