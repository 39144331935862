import { CdkFixedSizeVirtualScroll, CdkVirtualForOf, CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { AsyncPipe, NgTemplateOutlet } from '@angular/common';
import {
  Component,
  contentChild,
  effect,
  ElementRef,
  input,
  InputSignal,
  Signal,
  TemplateRef,
  viewChild,
} from '@angular/core';

@Component({
  selector: 'ph-virtual-list',
  templateUrl: './virtual-list.component.html',
  styleUrls: ['./virtual-list.component.scss'],
  standalone: true,
  imports: [CdkVirtualScrollViewport, CdkFixedSizeVirtualScroll, CdkVirtualForOf, NgTemplateOutlet, AsyncPipe],
  host: {
    '[style.--ph-virtual-list-gap]': 'gap()',
  },
})
export class PhVirtualListComponent {
  template = contentChild(TemplateRef);
  vsComponent: Signal<ElementRef> = viewChild.required('scroll', { read: ElementRef });
  gap: InputSignal<string> = input.required({ transform: (val: string) => `${val}px` });
  items: InputSignal<unknown[]> = input.required();

  constructor() {
    effect(() => {
      if (this.items()) {
        this.vsComponent().nativeElement.scrollTop = 0;
      }
    });
  }

  trackByID(index: number): number {
    return index;
  }
}
