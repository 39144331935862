<header ph-dialog-header>
  <ph-notification-icon [type]="type || notification?.type"/>
  <h2 ph-notification-title>{{ notification?.value?.title || value?.title }}</h2>
  <ph-icon name="cross" width="24px" height="24px" (click)="close()" data-test-hook="notification.close"/>
</header>

@if (notification?.value?.message || value?.message) {
  <p ph-notification-content>
    {{ notification?.value?.message || value?.message }}
  </p>
}
@if (notification?.value?.innerHtml) {
  <p ph-notification-content [innerHTML]="notification?.value?.innerHtml"></p>
}

@if (notification?.actions || actions; as actions) {
  <div
    [style.grid-template-columns]="'repeat(' + actions.length + ', 1fr)'"
    ph-dialog-actions
  >
    @for (action of actions; track action) {
      @if (action?.text) {
        <button
          (click)="action?.act && act(action.act)"
          state="secondary"
          ui-ph-button
          size="small"
          borderless
        >
          {{ action.text }}
        </button>
      }
    }
  </div>
}
