<header ph-dialog-header>
  <h2 class="update-password-popup-title" ph-notification-title>
    {{ 'loginPage.updatePassword.updatePassword' | translate }}
  </h2>
  <ph-icon name="cross" width="24px" height="24px" (click)="close()" />
</header>

@if (!emailSuccess) {
  <form [formGroup]="requestForm">
    <div class="hint-password">
      <p>
        <strong>{{ 'loginPage.updatePassword.hint' | translate }} :</strong>
        {{ 'loginPage.updatePassword.passwordMustContain' | translate }}
      </p>
      <ul>
        @if (data?.flowType === 'LEGACY') {
          <li>{{ 'loginPage.updatePassword.legacy.oneLetter' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.legacy.oneDigit' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.legacy.oneSpecialCharacter' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.legacy.minimumLength' | translate }}</li>
        }
        @if (data?.flowType === 'OKTA') {
          <li>{{ 'loginPage.updatePassword.okta.lowerCaseLetted' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.okta.upperCaseLetted' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.okta.digit' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.okta.oneSpecialCharacter' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.okta.minimumLength' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.okta.username' | translate }}</li>
          <li>{{ 'loginPage.updatePassword.okta.history' | translate }}</li>
        }
      </ul>
    </div>

    @if (data?.actionType === 'UPDATE') {
      <ph-form-input-control
        formControlName="currentPassword"
        [label]="'loginPage.updatePassword.currentPassword' | translate"
        type="password"
      />
    }
    <ph-form-input-control
      formControlName="newPassword"
      [label]="'loginPage.updatePassword.newPassword' | translate"
      type="password"
    />
    <ph-form-input-control
      formControlName="confirmPassword"
      [label]="'loginPage.updatePassword.confirmNewPassword' | translate"
      type="password"
    />
  </form>
}

@if (!emailSuccess) {
  <div ph-dialog-actions>
    <button ui-ph-button size="small" state="secondary" (click)="close()" ph-overlay-close="cancel">
      {{ 'loginPage.updatePassword.cancel' | translate }}
    </button>

    <button ui-ph-button size="small" (click)="updatePassword()" [disabled]="requestForm.invalid" ph-overlay-close="add">
      {{ 'loginPage.updatePassword.submit' | translate }}
    </button>
  </div>
} @else {
  <div class="success-block">
    <p class="success-block-text">{{ 'loginPage.updatePassword.passwordUpdated' | translate }}</p>
    <button
      class="success-block-btn"
      type="button"
      ui-ph-button
      size="small"
      ph-overlay-close="cancel"
      (click)="close()"
    >
      {{ 'loginPage.updatePassword.continue' | translate }}
    </button>
  </div>
}
