import { DatePipe } from '@angular/common';
import { Component, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';

import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';

import { environment } from '@ph-env/environment';

import { ContractType } from '@ph-model/api';
import { UserResponse } from '@ph-model/login/user.response.model';
import { selectUser } from '@ph-store/user/user.selectors';

@Component({
  templateUrl: './remit-print-template.component.html',
  styleUrls: ['./remit-print-template.component.scss'],
  standalone: true,
  imports: [DatePipe, TranslateModule],
})
export class RemitPrintTemplateComponent {
  contracts: WritableSignal<ContractType[]> = signal([]);
  remitted: WritableSignal<boolean> = signal(false);
  navbarLogo: WritableSignal<string | ArrayBuffer> = signal('');
  totalDealerCost: WritableSignal<number> = signal(0);

  user: Signal<UserResponse> = toSignal(this.store.select(selectUser));
  date: Date = new Date();
  verbiage = environment.verbiage;

  constructor(private store: Store) {}
}
