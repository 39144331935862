@if (record()) {
  <div class="operation">
    <p class="operation-date text-left">{{ record().date | date: 'medium' }}</p>
    <div class="operation-description">
      <div class="operation-author">
        <p>{{ displayName() }}</p>
        @if (displayUserName && userName()) {
          <p class="operation-username">{{ userName() }}</p>
        }
      </div>
      <div class="operation-status">
        <ph-status-chip [type]="getChipType(record().operation)" [label]="getChipLabel(record().operation) | translate" />
      </div>
    </div>
  </div>
}
