<div
  class="select-dropdown"
  [class.select-dropdown--disabled]="disabled"
  [class.select-dropdown--invalid]="showError"
  tabindex="0"
>
  @if (label()) {
    <ph-form-control-label
      [label]="label()"
      [required]="required()"
      [disabled]="disabled"
      [invalid]="showError"
    >
      <ng-content select="[label]"/>
    </ph-form-control-label>
  }

  <div class="select-dropdown__input" (click)="toggleExpand()">
    @if (hasValue) {
      <span class="select-dropdown__value">
        <ng-content select=".ph-prefix"/>
        {{ labelFormatter() ? labelFormatter()(valueLabel) : valueLabel }}
        <ng-content select=".ph-suffix"/>
      </span>
    } @else {
      <span class="select-dropdown__placeholder">{{ placeholder() }}</span>
    }

    <div class="select-dropdown__toggle">
      <div class="select-dropdown__icon">
        <ph-icon [name]="!isExpanded ? 'arrow_down' : 'arrow_up'"/>
      </div>
    </div>
  </div>

  @if (isExpanded) {
    <div class="options">
      <ul class="options__list">
        @for (option of options; track option) {
          <li class="options__item" (click)="select(option.value)">{{ option.label }}</li>
        }
      </ul>
    </div>
  }
</div>

@if (isExpanded) {
  <div class="select-dropdown--overlay" (click)="closeDropdown()"></div>
}

@if (showError) {
  <ph-form-control-error [errorMessage]="errorMessage"/>
}
