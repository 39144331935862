import { ActionReducerMap } from '@ngrx/store';

import * as CartReducer from './cart/cart.reducer';
import * as ClaimReducer from './claims/claims.reducer';
import * as ConfigReducer from './config/config.reducer';
import * as HistoryReducer from './history/history.reducer';
import * as PrismicReducer from './prismic/prismic.reducer';
import * as ProductReducer from './product/product.reducer';
import * as RemitReducer from './remit/remit.reducer';
import * as UserReducer from './user/user.reducer';
import * as VehicleReducer from './vehicle/vehicle.reducer';
import * as ShoppingStepperReducer from '../shared/modules/shopping-stepper/store/shopping-stepper.reducer';

export interface State {
  productState: ProductReducer.ProductState;
  configState: ConfigReducer.ConfigState;
  cartState: CartReducer.CartState;
  userState: UserReducer.UserState;
  vehicleState: VehicleReducer.VehicleState;
  remitState: RemitReducer.RemitState;
  historyState: HistoryReducer.HistoryState;
  claimState: ClaimReducer.ClaimState;
  prismicState: PrismicReducer.PrismicState;
  shoppingStepperState: ShoppingStepperReducer.ShoppingStepperState;
}

export const reducers: ActionReducerMap<State> = {
  cartState: CartReducer.reducer,
  claimState: ClaimReducer.reducer,
  configState: ConfigReducer.reducer,
  historyState: HistoryReducer.reducer,
  prismicState: PrismicReducer.reducer,
  productState: ProductReducer.reducer,
  remitState: RemitReducer.reducer,
  userState: UserReducer.reducer,
  vehicleState: VehicleReducer.reducer,
  shoppingStepperState: ShoppingStepperReducer.reducer,
};
