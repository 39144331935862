@if (label()) {
  <ph-form-control-label [label]="label()" [required]="required()" [disabled]="disabled" [invalid]="showError">
    <ng-content select="[label]"/>
  </ph-form-control-label>
}

<div class="option-wrapper">
  @for (option of options(); track option) {
    <label class="radio-input" [class.disabled]="disabled || option.disabled">
      <input
        type="radio"
        [checked]="option.value === value"
        [disabled]="disabled || option.disabled"
        (change)="updateValue(option.value, $event)"
      />
      {{ option.label }}
      <span class="checkmark"></span>
    </label>
  }
</div>

@if (showError) {
  <ph-form-control-error [errorMessage]="errorMessage"/>
}
