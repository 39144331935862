<header ph-dialog-header>
  <h2 class="title" ph-notification-title>{{ 'loginPage.requestAccount.requestCredentials' | translate }}</h2>
  <ph-icon name="cross" width="24px" height="24px" (click)="close()"/>
</header>

<form [formGroup]="requestForm">
  <ph-form-input-control formControlName="firstName" [label]="'loginPage.requestAccount.firstName' | translate"/>
  <ph-form-input-control formControlName="lastName" [label]="'loginPage.requestAccount.lastName' | translate"/>
  <ph-form-input-control formControlName="title" [label]="'loginPage.requestAccount.title' | translate"/>
  <ph-form-input-control formControlName="phone" [label]="'loginPage.requestAccount.phone' | translate"/>
  <ph-form-input-control
    class="full-width"
    formControlName="email"
    [label]="'loginPage.requestAccount.email' | translate"
  />
  <ph-form-input-control formControlName="dealer" [label]="'loginPage.requestAccount.dealerNumber' | translate"/>

  <div class="dealer-actions">
    <button ui-ph-button size="small" state="secondary" (click)="resetDealer()">
      {{ 'loginPage.requestAccount.reset' | translate }}
    </button>
    <button
      ui-ph-button
      size="small"
      [disabled]="isSearching || !requestForm.get('dealer').value"
      (click)="verifyDealer()"
    >
      {{ 'loginPage.requestAccount.verify' | translate }}
    </button>
  </div>

  <div formGroupName="dealerInfo" class="full-width dealer-block">
    <ph-form-input-control
      class="full-width"
      formControlName="dealerName"
      [label]="'loginPage.requestAccount.dealerName' | translate"
    />
    <ph-form-input-control
      class="full-width"
      formControlName="dealerAddress1"
      [label]="'loginPage.requestAccount.dealerAddress' | translate"
    />
    <ph-form-input-control formControlName="dealerZipCode" [label]="'loginPage.requestAccount.dealerZip' | translate"/>
    <ph-form-input-control formControlName="dealerCity" [label]="'loginPage.requestAccount.dealerCity' | translate"/>
    <ph-form-input-control formControlName="dealerState" [label]="'loginPage.requestAccount.dealerState' | translate"/>
  </div>
  @if (supportCaptcha) {
    <ngx-recaptcha2 class="full-width" [siteKey]="recaptchaSiteKey" formControlName="captcha" [hl]="langValue"/>
  }

</form>

@if (!emailSuccess) {
  <div ph-dialog-actions>
    <button ui-ph-button size="small" state="secondary" (click)="close()" ph-overlay-close="cancel">
      {{ 'loginPage.requestAccount.cancel' | translate }}
    </button>

    <button
      ui-ph-button
      size="small"
      (click)="submitRequestAccount()"
      [disabled]="requestForm.invalid && !isSearching"
      ph-overlay-close="add"
    >
      {{ 'loginPage.requestAccount.submit' | translate }}
    </button>
  </div>
} @else {
  <div class="success-block">
    <p class="success-block-text">{{ 'loginPage.requestAccount.requestSent' | translate }}</p>
    <button
      class="success-block-btn"
      type="button"
      ui-ph-button
      size="small"
      ph-overlay-close="cancel"
      (click)="close()"
    >
      {{ 'loginPage.requestAccount.continue' | translate }}
    </button>
  </div>
}

